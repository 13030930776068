body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}
/* font family classes */
.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
/* font family classes */

.main-container {
  width: 90%;
  max-width: 1360px;
  margin-left: auto;
  margin-right: auto;
}

.row-main-header {
  padding-top: 9px;
  padding-bottom: 9px;
}

.header-logo {
  cursor: pointer;
}

.leftAlignCenter {
  display: flex;
  align-items: center;
}
.menu-ul {
  display: flex;
  align-items: center;
  justify-content: start;
}
.menu-ul > a {
  text-decoration: none;
}
.menu-ul > a > div {
  font-size: 16px;
  margin-left: 30px;
  cursor: pointer;
  color: #000000;
  border-bottom: 2px solid #fff;
}

.menu-ul > a > div.activeMenu{
  color:#2A4312;
  border-bottom: 2px solid #2A4312;
}

.menu-ul-right {
  margin: 0px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 18px 0px;
}

.menu-ul-right button {
  font-size: 16px;
  background: transparent;
  border: none;
  color: #000000;
  padding: 8px 32px;
}

.menu-ul-right button.button-request {
  background: #d6ecf3;
  border-radius: 10px;
  color: #070707;
}

.section-header {
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.bookDemoNow {
  padding: 30px 0px;
  display: flex;
  align-items: center;
}
.titleSection {
  font-size: 36px;
  line-height: 48px;
}
.titlePera {
  font-size: 16px;
  line-height: 24px;
  color: #787679;
  text-align: justify;
}
.bookButton {
  background-color: #d6ecf3;
  padding: 8px 26px;
  border-radius: 10px;
  border: 1px solid #d6ecf3;
  color: #000000;
}
.bookButton:hover,
.contactBookDemo:active {
  color: #000000;
  border: 1px solid #91ddf5 !important;
  background-color: #91ddf5 !important;
}
.ourSolutionPadding {
  padding: 15px;
}
.shadeSection {
  box-shadow: 0px 0px 6px 2px #00000040;
  border-radius: 10px;
  padding: 25px;
  height: 100%;
  position: relative;
}
.outerPadding {
  padding: 16px;
}
.ourSolutionTitle {
  font-size: 34px;
  line-height: 40px;
  text-align: center;
  padding: 50px 0px;
}
.headerImg {
  text-align: center;
}

.descriptionSection > h3 {
  padding-top: 15px;
  font-size: 22px;
  line-height: 32px;
  color: #2a4312;
}
.descriptionSection > h6 {
  font-size: 18px;
  line-height: 22px;
  color: #7b7b7b;
}
.descriptionSection > p {
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
  padding-bottom: 40px;
}
.btnSection {
  text-align: end;
  position: absolute;
  bottom: 25px;
  right: 25px;
}
.mainCustomerSection {
  position: relative;
}
.customerShadeSection {
  box-shadow: 0px 0px 6px 2px #00000040;
  border-radius: 10px;
  height: 100%;
}
.backgroundImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 248px;
  border-radius: 10px 10px 0px 0px;
}
.customerShadeSection > p {
  padding: 20px 20px 50px;
  font-size: 16px;
  line-height: 24px;
  text-align: justify;
}
.customerShadeSection > h6 {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
}

.shadeSectionSolutions {
  box-shadow: 0px 0px 6px 2px #00000040;
  border-radius: 10px;
  margin: 50px 0px 100px;
  padding: 50px 100px;
}
.manageSection {
  display: flex;
}
.titleSolutions {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
}
.titleSolutionsPera {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 10px;
}
.manageImgIcon > img {
  height: 45px;
  max-width: 45px;
}
.manageDescriptions {
  padding: 0px 30px 0px 12px;
}
.manageDescriptions > h4 {
  font-size: 16px;
  line-height: 24px;
}
.manageDescriptions > p {
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
}

/* footer */

.footerSection {
  background-color: #eaf0f0;
  padding: 30px 0px;
}
.footerDescription > h6 {
  font-size: 16px;
  line-height: 20px;
}
.footerDescription > p {
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}
.footerSocialAlign {
  display: flex;
  align-items: end;
  justify-content: center;
  flex-direction: column;
}
.socialLinks {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 14px;
}
.socialLinks > img {
  padding-left: 20px;
}
.aboutTabs {
  display: flex;
  align-items: center;
  justify-content: end;
}
.aboutTabs > div {
  font-size: 16px;
  line-height: 22px;
  padding-left: 48px;
  cursor: pointer;
}
.policySection {
  display: flex;
  align-items: center;
  justify-content: end;
}
.policySection > div {
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
  text-decoration: underline;
  cursor: pointer;
}
.copyrightText {
  display: flex;
  align-items: center;
}
.copyrightText > p {
  font-size: 14px;
  line-height: 20px;
}

/* posin Pro */

.spantitle {
  color: #2a4312;
}
.wirelessOrderSection {
  background-color: #f8f8f6;
}
.wirelessOrderCol {
  padding: 40px 0px;
}
.wirelessOrderCol > h2 {
  font-size: 30px;
  line-height: 36px;
}
.wirelessOrderCol > h6 {
  font-size: 24px;
  line-height: 30px;
}
.wirelessOrderCol > p {
  font-size: 18px;
  line-height: 26px;
  text-align: justify;
}
.Cardheadersection {
  text-align: center;
}
.Cardheadersection > h3 {
  font-size: 30px;
  line-height: 36px;
  color: #2a4312;
  padding-bottom: 30px;
}
.Cardheadersection > p {
  font-size: 20px;
  line-height: 26px;
  padding-bottom: 10px;
}

.cardSection {
  box-shadow: 0px 0px 6px 2px #00000040;
  border-radius: 10px;
  text-align: center;
  padding: 40px 0px;
  margin: 0px 15px;
}
.cardSection > h6 {
  font-size: 26px;
  line-height: 32px;
  padding-bottom: 20px;
}
.businessCardSection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 0px;
}


/* posInMini */
.PosSoftwarePadding{
  padding-bottom: 45px;
}
.varaitionAlign{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:55px 0px;
}
.varaitionAlign>h4{
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  padding-bottom: 55px;
}
.varationsImages{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height:298px;
  width:298px;
  border-radius: 10px;
  margin:10px;
}


/* contact */

.getinTouch{
  color:#2A4312;
}
.contactColsection{
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  padding:80px 0px;
  background-color: #D6ECF3;
}
.contactBookDemo{
  background-color: #A3BAB0;
}
.contactBookDemo:hover,
.contactBookDemo:active{
  background-color: #A3BAB0 !important;
  border:1px solid #A3BAB0 !important;
}

.supportSectionCenter{
  display: flex;
  justify-content: center;
  position: relative;
  bottom:20px;
}
.contactCardSection{
  box-shadow: 0px 0px 6px 2px #00000040;
  background-color: #fff;
  border-radius: 10px;
  padding:30px;
  text-align: center;
  margin:0px 15px;
}
.contactCardSection>img{
  padding-bottom: 20px;
}
.contactCardSection>h6{
  font-size: 24px;
  line-height: 30px;
  padding-bottom: 20px;
}
.contactCardSection>p{
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
}

.contactPriceHeader{
  text-align: center;
  padding:60px 0px;
}
.contactPriceHeader>h2{
  font-size: 34px;
  line-height: 46px;
  padding-bottom: 5px;
}
.contactPriceHeader>p{
  font-size: 14px;
  line-height: 20px;
}



.maincontactPriceSection{
  padding-bottom: 150px;
}
.priceCenterSection{
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceCardSection{
  box-shadow: 0px 0px 6px 2px #00000040;
  background-color: #fff;
  border-radius: 10px;
  padding:10px 25px 48px;
  text-align: center;
}
.priceCardSection>h1{
  font-size: 26px;
  line-height: 32px;
  padding: 15px 0px 5px;
  text-transform: uppercase;
}
.priceCardSection>p{
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
}
.pricebtn{
  background-color:#2A4312;
  color:#fff;
  width:100%;
  border-radius: 30px;
  padding:12px 26px;
  text-transform: uppercase;
}
.pricebtn:hover,
.pricebtn:active{
  background-color: #2A4312 !important;
  border:1px solid #2A4312 !important;
  color:#fff;
}
.priceDetails{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.price{
  padding-right: 10px;
  font-size: 54px;
  line-height: 64px;
}
.price>span{
  font-size: 24px;
  line-height: 56px;
  vertical-align: text-top;
}
.priceper{
  padding-left: 10px;
  text-align: left;
  border-left: 2px solid #787679;
}


@media only screen and (max-width: 768px) {
  .leftAlignCenter{
    flex-direction: column;
  }
  .menu-ul > a > div{
    margin: 0px 10px;
    padding:7px 0px 0px;
  }
  .menu-ul-right{
    flex-direction: column;
    padding:10px 0px 18px;
  }
  .titleSection{
    font-size: 26px;
    line-height: 38px;
  }
  .ourSolutionTitle{
    padding:50px 0px 0px;
  }
  .mainCustomerSection{
    margin-bottom: 25px;
  }
  .shadeSectionSolutions{
    padding:40px 20px;
  }
  .titleSolutions{
    font-size: 24px;
    line-height: 32px;
  }
  .manageDescriptions{
    padding:0px 0px 0px 12px;
  }
  .aboutTabs{
    flex-direction: column;
    justify-content: center;
  }
  .aboutTabs > div{
    padding:5px 10px;
  }
  .footerSocialAlign{
    align-items: center;
  }
  .socialLinks{
    padding:15px 0px;
  }
  .socialLinks > img{
    padding:0px 10px;
  }
  .footerlogo{
    text-align: center;
  }

  .wirelessOrderCol > h2{
    padding-bottom: 10px;
  }
  .wirelessOrderCol > h6{
    padding:10px 0px;
  }
  .cardSection{
    margin: 15px;
  }
  .businessCardSection{
    padding-bottom: 20px;
  }
  .contactColsection{
    padding:30px 0px 220px;
    background-position: bottom;
  }
  .contactCardSection{
    margin:15px;
  }
  .contactPriceHeader{
    padding:20px 20px 50px;
  }
  .contactPriceHeader>h2{
    padding-bottom: 20px;
  }
  .priceCardMob{
    padding:20px;
  }
  .maincontactPriceSection{
    padding-bottom: 50px;
  }
  .mobileImgPos{
    padding: 20px;
  }
}